import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import Layout from '../components/Layout';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import VideoEmbedd from '../components/VideoEmbedd';
import ContactForm from '../pages/contacto/index';
import { htmlParser } from '../helpers/htmlParser';

function parseString(str) {
  const parts = str.split(/(,\s)/);
  return parts.map((part, i) => {
    if (part === ', ') {
      return <span key={i}>,<br/></span>;
    } else {
      return <span key={i}>{part}</span>;
    }
  });
}

// eslint-disable-next-line
export const WorkshopsPageTemplate = ({
  image,
  title,
  subtitle,
  description,
  video,
  isShorts,
  banner
}) => {
  const bannerImage = getImage(banner) || banner;

  return (
    <div>
      <section className="section section--gradient">
        <div className="container">
          <div className="project--info">
            <div className="columns">
              <div className="column is-8 is-offset-2">
                {image && (
                  <div className="project-logo-container featured-thumbnail">
                    <PreviewCompatibleImage
                      className={'project-logo-img'}
                      imageInfo={{
                        image: image,
                        alt: `${subtitle}`,
                        width: image.childImageSharp.gatsbyImageData.width,
                        height: image.childImageSharp.gatsbyImageData.height
                      }}
                    />
                  </div>
                )}
                <div className="workshops-titles">
                  <h2 className="">{title}</h2>
                  <h3 className="page-title workshops-title">{parseString(subtitle)}</h3>
                </div>
                <div className="description project-desc">
                  {htmlParser(description)}
                </div>
              </div>
            </div>
            <div className="workshop-video has-text-centered">
              <div className="columns">
                <div className="column is-8 is-offset-2">
                  <VideoEmbedd src={video} isShorts={isShorts} />
                </div>
              </div>
            </div>
            {/* <div className="workshop-cta has-text-centered">
              <div className="columns">
                <div className="column is-10 is-offset-1 carousel">
                  <Link to={parseURL('workshops')}>
                    <button className="button cta-button">Contacto</button>
                  </Link>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <div
        className="watermark-container"
        style={{ backgroundColor: 'white', height: 250 }}
      >
        <div
          className="watermark"
          style={{
            backgroundImage: `url(${bannerImage.images.fallback.src})`
          }}
        />
      </div>{' '}
      <section id="contacto" className="section section--gradient">
        <div className="container">
          <ContactForm />
        </div>
      </section>
    </div>
  );
};

WorkshopsPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  video: PropTypes.string,
  isShorts: PropTypes.bool,
};

const WorkshopsPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <WorkshopsPageTemplate
        image={post.frontmatter.image}
        title={post.frontmatter.title}
        subtitle={post.frontmatter.subtitle}
        description={post.frontmatter.description}
        video={post.frontmatter.video}
        isShorts={post.frontmatter.isShorts}
        banner={post.frontmatter.banner}
      />
    </Layout>
  );
};

WorkshopsPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default WorkshopsPage;

export const workshopsPageQuery = graphql`
  query WorkshopsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        image {
          childImageSharp {
            gatsbyImageData(height: 120, quality: 100, layout: CONSTRAINED)
          }
        }
        title
        subtitle
        description
        video
        isShorts
        banner {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
