import * as React from 'react';

import Layout from '../../components/Layout';
import ShopRoll from "../../components/ShopRoll";

export default class ShopIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <section className="section">
          <div className="columns is-centered">
            <div className="column is-narrow has-text-centered">
              <h2 className="title page-title about-title">Loja</h2>
            </div>
          </div>
          <div className="container">
            <div className="content">
              <ShopRoll />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
