import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import PreviewCompatibleImage from './PreviewCompatibleImage';

const ShopRollTemplate = (props) => {
  const { edges: items } = props.data.allMarkdownRemark;

  return (
    <div className="columns is-multiline">
      {items &&
        items
          .filter(({ node: item }) => item.frontmatter.show) // Filter items based on `show` field
          .map(({ node: item }) => (
            <div
              className="column is-12-mobile is-6-tablet is-4-desktop"
              key={item.id}
            >
              <Link to={item.fields.slug} className="shop-item-card">
                <div className="shop-item-card-image">
                  {item.frontmatter.featuredimage && (
                    <figure>
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: item.frontmatter.featuredimage,
                          alt: `featured image thumbnail for item ${item.frontmatter.title}`
                        }}
                      />
                    </figure>
                  )}
                </div>
                <div className="shop-item-card-content">
                  <div className="columns is-mobile">
                    <div className="column is-8">
                      <div className="media-content shop-item-card-info">
                        <h5 className="shop-item-card-title">
                          {item.frontmatter.title}
                        </h5>
                        <p className="shop-item-card-price">
                          {item.frontmatter.price},00
                        </p>
                      </div>
                    </div>
                    <div className="column is-4">
                      {item.frontmatter.edition === 'new' && (
                        <PreviewCompatibleImage
                          className={'shop-item-new-edition-tag'}
                          imageInfo={{
                            image: '/img/shop-item-new-edition.png',
                            alt: `New edition for shop item ${item.frontmatter.title}`
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
    </div>
  );
};

ShopRollTemplate.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export default function ShopRoll() {
  return (
    <StaticQuery
      query={graphql`
        query ShopRollQuery {
          allMarkdownRemark(
            sort: { order: ASC, fields: [frontmatter___order] }
            filter: { frontmatter: { templateKey: { eq: "shop-item" } } }
          ) {
            edges {
              node {
                id
                fields {
                  slug
                }
                frontmatter {
                  show
                  title
                  subtitle
                  description
                  price
                  edition
                  featuredimage {
                    childImageSharp {
                      gatsbyImageData(
                        width: 460
                        quality: 100
                        layout: CONSTRAINED
                      )
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <ShopRollTemplate data={data} count={count} />}
    />
  );
}
