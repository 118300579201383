import React from 'react';

export default function VideoEmbedd({ src, isShorts }) {
  // Regular expression pattern to extract the video ID
  const videoIdPattern =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})(?:\S+)?$/;

  // Extract the video ID from the YouTube link
  const match = src.match(videoIdPattern);
  const videoId = match ? match[1] : null;

  // Construct the embedded source URL
  const embeddedUrl = videoId ? `https://www.youtube.com/embed/${videoId}` : '';

  return (
    <div>
      {isShorts ? (
        <div className='video-shorts'>
          <iframe
            width="246"
            height="436"
            src="https://www.youtube.com/embed/1wFPdWLT1VE"
            title="dji export 20230502 124408 1683027848184 editor"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      ) : (
        <div className="video-container">
          <iframe
            className="video-iframe"
            src={embeddedUrl}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullscreen
          ></iframe>
        </div>
      )}
    </div>
  );
}
