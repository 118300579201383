import React from 'react';
import PropTypes from 'prop-types';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import Readmore from '../components/Readmore';

import { htmlParser } from '../helpers/htmlParser';

const TeamGrid = ({ gridItems }) => {
  return (
    <div className="columns is-multiline is-centered grid-gap">
      {gridItems.map((item, index) => (
        <div key={item.name} className="column is-6">
          <div className="team-card is-flex is-flex-direction-column is-justify-content-between">
            <div className="team-card-top">
              <div className="team-picture" style={{ width: '240px', display: 'inline-block' }}>
                <PreviewCompatibleImage className={'team-member-img'} imageInfo={item} />
              </div>
              <div className="content team-content">
                <h2>{item.name}</h2>
                <h4 className="page-title team-nickname-title">
                  <strong>{item.nickname}</strong>
                </h4>
                <h5>{htmlParser(item.subtitle)}</h5>
                <div>{htmlParser(item.description)}</div>
              </div>
            </div>
            <div className="team-card-bottom">
              <div className="btn-readmore">
                <Readmore readmore={item.readmore} />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

TeamGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      alt: PropTypes.string,
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      name: PropTypes.string,
      nickname: PropTypes.string,
      subtitle: PropTypes.string,
      description: PropTypes.string,
      readmore: PropTypes.string
    })
  )
};

export default TeamGrid;
