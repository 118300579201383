import React from 'react';
import Slider from 'react-slick';
import PreviewCompatibleImage from './PreviewCompatibleImage';

// const styleRight = {
//   display: 'inline-block',
//   border: '40px solid transparent',
//   borderLeftColor: '#4a1c22',
//   marginLeft: '40px'
// };

function SampleNextArrow(props) {
  const { onClick } = props;
  return <div className="slick-arrow slick-next" onClick={onClick} />;
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'green' }}
      v
      onClick={onClick}
    />
  );
}

export default function Carousel({ images }) {
  const slider = React.useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3500,
    cssEase: 'ease-out',
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };
  return (
    <div className="carousel-container columns is-centered is-vcentered is-mobile
">
      <div className="column is-narrow is-flex is-justify-content-center is-align-items-center">
        <div
          className="caret caret-prev"
          onClick={() => slider?.current?.slickPrev()}
        />
      </div>
      <div className="column is-12">
      <Slider className="carousel-img" ref={slider} {...settings}>
        {images &&
          images.map((image, idx) => (
            <div key={idx}>
              <PreviewCompatibleImage imageInfo={image} />
            </div>
          ))}
      </Slider>

      </div>
      <div className="column is-narrow is-flex is-justify-content-center is-align-items-center">
        <div
          className="caret caret-next"
          onClick={() => slider?.current?.slickNext()}
        />
      </div>
    </div>
  );
}
