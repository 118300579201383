exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contacto-examples-js": () => import("./../../../src/pages/contacto/examples.js" /* webpackChunkName: "component---src-pages-contacto-examples-js" */),
  "component---src-pages-contacto-file-upload-js": () => import("./../../../src/pages/contacto/file-upload.js" /* webpackChunkName: "component---src-pages-contacto-file-upload-js" */),
  "component---src-pages-contacto-index-js": () => import("./../../../src/pages/contacto/index.js" /* webpackChunkName: "component---src-pages-contacto-index-js" */),
  "component---src-pages-contacto-obrigado-js": () => import("./../../../src/pages/contacto/obrigado.js" /* webpackChunkName: "component---src-pages-contacto-obrigado-js" */),
  "component---src-pages-loja-index-js": () => import("./../../../src/pages/loja/index.js" /* webpackChunkName: "component---src-pages-loja-index-js" */),
  "component---src-pages-loja-obrigado-js": () => import("./../../../src/pages/loja/obrigado.js" /* webpackChunkName: "component---src-pages-loja-obrigado-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacy-policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-shop-item-js": () => import("./../../../src/templates/shop-item.js" /* webpackChunkName: "component---src-templates-shop-item-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-terms-and-conditions-js": () => import("./../../../src/templates/terms-and-conditions.js" /* webpackChunkName: "component---src-templates-terms-and-conditions-js" */),
  "component---src-templates-workshops-page-js": () => import("./../../../src/templates/workshops-page.js" /* webpackChunkName: "component---src-templates-workshops-page-js" */)
}

