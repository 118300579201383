import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSwipeable } from 'react-swipeable';
import PreviewCompatibleImage from './PreviewCompatibleImage';

const ShopItemImage = ({ featuredimage, images, title }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const allImages = [featuredimage, ...images.filter(img => img.image !== null).map(img => img.image)];

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => setSelectedImageIndex((selectedImageIndex + 1) % allImages.length),
    onSwipedRight: () => setSelectedImageIndex((selectedImageIndex - 1 + allImages.length) % allImages.length),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  return (
    <div className="shop-item-images">
      <div className="shop-item-image featured-image" {...swipeHandlers}>
        <PreviewCompatibleImage imageInfo={{ image: allImages[selectedImageIndex], alt: title }} />
      </div>
      <div className="shop-item-image-gallery">
        {allImages.map((img, index) => (
          img && (
            <div
              key={index}
              className={`shop-item-image-thumbnail ${index === selectedImageIndex ? 'active' : ''}`}
              onClick={() => handleImageClick(index)}
              style={{ cursor: 'pointer', margin: '0.5rem' }}
            >
              <PreviewCompatibleImage imageInfo={{ image: img, alt: title }} />
            </div>
          )
        ))}
      </div>
    </div>
  );
};

ShopItemImage.propTypes = {
  featuredimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
      alt: PropTypes.string
    })
  ).isRequired,
  title: PropTypes.string.isRequired
};

export default ShopItemImage;
