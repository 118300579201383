import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { graphql, navigate } from 'gatsby';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import ShopItemImage from '../components/ShopItemImage';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export const ShopItemTemplate = ({
  content,
  contentComponent,
  description,
  title,
  edition,
  subtitle,
  featuredimage,
  price,
  sizes,
  images,
  helmet
}) => {
  const PageContent = contentComponent || Content;

  class OrderForm extends React.Component {
    constructor(props) {
      super(props);
      this.state = { isValidated: false };
    }

    handleChange = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };

    handleSubmit = (e) => {
      e.preventDefault();
      const form = e.target;
      const formData = {
        'form-name': form.getAttribute('name'),
        title: this.props.title,
        ...this.state
      };
      console.log('Form Data: ', formData); // Add this line to print form data
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode(formData)
      })
        .then(() => navigate(form.getAttribute('action')))
        .catch((error) => alert(error));
    };

    render() {
      const { title } = this.props;

      return (
        <form
          name="order"
          method="post"
          action="/loja/obrigado/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
          className="shop-item-form"
        >
          <input type="hidden" name="form-name" value="order" />
          <input type="hidden" name="title" value={title} />
          <div hidden>
            <label>
              Don’t fill this out:{' '}
              <input name="bot-field" onChange={this.handleChange} />
            </label>
          </div>
          <div>
            <h3>
              <b>Encomendar</b>
            </h3>
          </div>
          <div className="field shop-item-form__field">
            <label className="label shop-item-form__label" htmlFor="size">
              Tamanho
            </label>
            <div className="control select is-normal">
              <select
                name="size"
                id="size"
                required
                onChange={this.handleChange}
                className="select shop-item-form__select"
              >
                <option value="" disabled selected>
                  Selecionar tamanho
                </option>
                {sizes.map((size, index) => (
                  <option key={index} value={size.option}>
                    {size.option}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="field shop-item-form__field">
            <label className="label shop-item-form__label" htmlFor={'email'}>
              Email
            </label>
            <div className="control">
              <input
                className="input shop-item-form__input"
                type="email"
                name="email"
                id="email"
                required
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="shop-item-form__field">
            <button className="button page-button is-link" type="submit">
              Enviar
            </button>
          </div>
          <p>
            Ainda não é possível comprar directamente na nossa loja on-line e
            enviar. Envia-nos a tua encomenda e entraremos em contacto contigo
            com as possibilidades de pagamento e envio!
          </p>
        </form>
      );
    }
  }

  return (
    <section className="section shop-item-section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column is-6 has-text-centered">
            <ShopItemImage
              featuredimage={featuredimage}
              images={images}
              title={title}
            />
            <PageContent content={content} />
          </div>
          <div className="column is-6">
            <div className="shop-title-section">
              <div className="columns is-mobile">
                <div className="column is-8">
                  <h1 className="title shop-item-title has-text-weight-bold is-bold-light">
                    {title}
                  </h1>
                </div>
                <div className="column is-">
                  {edition === 'new' && (
                    <PreviewCompatibleImage
                      className={'shop-item-new-edition-tag'}
                      imageInfo={{
                        image: '/img/shop-item-new-edition.png',
                        alt: `New edition for shop item ${title}`
                      }}
                    />
                  )}
                </div>
              </div>
              <p className="shop-item-card-price">{price},00</p>{' '}
              <h4 className="subtitle shop-item-subtitle">{subtitle}</h4>
              <p className="shop-item-description">{description}</p>
            </div>
            <OrderForm sizes={sizes} title={title} />
          </div>
        </div>
      </div>
    </section>
  );
};

ShopItemTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  edition: PropTypes.string,
  featuredimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  price: PropTypes.string,
  sizes: PropTypes.array,
  images: PropTypes.array,
  helmet: PropTypes.object
};

const ShopItem = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <ShopItemTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        edition={post.frontmatter.edition}
        title={post.frontmatter.title}
        subtitle={post.frontmatter.subtitle}
        featuredimage={post.frontmatter.featuredimage}
        price={post.frontmatter.price}
        sizes={post.frontmatter.sizes}
        images={post.frontmatter.images}
        helmet={
          <Helmet titleTemplate="%s | Shop">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
      />
    </Layout>
  );
};

ShopItem.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default ShopItem;

export const pageQuery = graphql`
  query ShopItemByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        subtitle
        description
        edition
        featuredimage {
          childImageSharp {
            gatsbyImageData(width: 460, quality: 90, layout: CONSTRAINED)
          }
        }
        price
        sizes {
          option
        }
        images {
          image {
            childImageSharp {
              gatsbyImageData(width: 400, quality: 90, layout: CONSTRAINED)
            }
          }
          alt
        }
      }
    }
  }
`;
