import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import PreviewCompatibleImage from './PreviewCompatibleImage';

const ProjectsTemplate = (props) => {
  const { edges: posts } = props.data.allMarkdownRemark;

  return (
    <div className="columns is-centered">
      {posts &&
        posts.map(({ node: post }) => (
          <div className="column is-4">
            <article className="project-card is-flex is-flex-direction-column is-justify-content-between">
              <Link className="project-card-link" to={post.fields.slug}>
                <div className="project-card-content-container">
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: post.frontmatter.logoLight,
                      alt: `${post.frontmatter.subtitle}`,
                      width:
                        post.frontmatter.logoLight.childImageSharp
                          .gatsbyImageData.width,
                      height:
                        post.frontmatter.logoLight.childImageSharp
                          .gatsbyImageData.height
                    }}
                  />
                  <div className="project-card-text">
                    <h4 className="project-card-title">
                      {post.frontmatter.title}
                    </h4>
                    <h5 className="project-card-subtitle">
                      <i>{post.frontmatter.subtitle}</i>
                    </h5>
                  </div>
                </div>
                
                <div className="project-card-button-container has-text-centered">
                  <div className="project-card-watermark"/>
                  <Link className="button page-button" to={post.fields.slug}>
                    Saber Mais
                  </Link>
                </div>

              </Link>
            </article>
          </div>
        ))}
    </div>
  );
};

Projects.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export default function Projects() {
  return (
    <StaticQuery
      query={graphql`
        query ProjectsQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "project" } } }
          ) {
            edges {
              node {
                excerpt(pruneLength: 400)
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  subtitle
                  description
                  templateKey
                  logoLight {
                    childImageSharp {
                      gatsbyImageData(
                        width: 250
                        quality: 100
                        layout: CONSTRAINED
                      )
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <ProjectsTemplate data={data} count={count} />}
    />
  );
}
