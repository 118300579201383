import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';

function parseString(str) {
  const parts = str.split(/(,\s)/);
  return parts.map((part, i) => {
    if (part === ', ') {
      return <span key={i}>,<br/></span>;
    } else {
      return <span key={i}>{part}</span>;
    }
  });
}

const WorkshopsMain = ({ workshops }) => {
  const { image, title, subtitle } = workshops;

  return (
    <div className="workshops-main">
      <Link to="/workshops">
        <div className="workshops-card-watermark" />
        <div className="workshops-card-content columns is-centered">
          <div className="column is-4">
            <article className="workshops-card is-flex is-flex-direction-column is-justify-content-center">
              <div className="workshops-card-content-container">
                <PreviewCompatibleImage
                  className={'workshops-logo'}
                  imageInfo={{
                    image: image,
                    alt: `Academia de Capoeira de Lisboa Workhops`,
                    width: image.childImageSharp.gatsbyImageData.width,
                    height: image.childImageSharp.gatsbyImageData.height
                  }}
                />
                <div className="workshops-card-text is-flex is-flex-direction-column">
                  <div className="has-text-centered">
                    <h1 className="title page-title workshops-card-title">
                      {title}
                    </h1>
                  </div>
                  
                  <h4 className=" workshops-card-subtitle">{parseString(subtitle)}</h4>
                </div>
              </div>

              {/* <div className="columns is-mobile">
                <div className="column is-half is-offset-one-quarter">
                  <div className="columns is-desktop">
                    <div className="column is-6">
                      <PreviewCompatibleImage
                        className={'is-pulled-right'}
                        imageInfo={image}
                      />
                    </div>
                    <div className="column workshops-text is-6">
                      <h1 className="title project-card-text page-title workshops-title post-meta">
                        {title}
                      </h1>
                      <h4 className=" project-card-text post-meta">
                        {subtitle}
                      </h4>
                    </div>
                  </div>
                </div>
              </div> */}
            </article>
          </div>
        </div>
        <div className="workshops-card-button-container has-text-centered">
          <Link className="button page-button" to="/workshops">
            Saber Mais
          </Link>
        </div>
      </Link>
    </div>
  );
};

WorkshopsMain.propTypes = {
  workshops: PropTypes.object
};

export default WorkshopsMain;
