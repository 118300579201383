import * as React from 'react';
import { navigate } from 'gatsby-link';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state
      })
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <div className="columns">
        <div className="column is-8 is-offset-2">
          <div className="content">
            <div className="columns is-centered">
              <div className="column is-narrow has-text-centered">
                <h2 className="title page-title contact-title">CONTACTO</h2>
              </div>
            </div>
            <form
              name="contact"
              method="post"
              action="/contacto/obrigado/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={this.handleSubmit}
            >
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="form-name" value="contact" />
              <div hidden>
                <label>
                  Don’t fill this out:{' '}
                  <input name="bot-field" onChange={this.handleChange} />
                </label>
              </div>
              <div className="field">
                <label className="label" htmlFor={'name'}>
                  Nome:
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={'text'}
                    name={'name'}
                    onChange={this.handleChange}
                    id={'name'}
                    required={true}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label" htmlFor={'email'}>
                  Email:
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={'email'}
                    name={'email'}
                    onChange={this.handleChange}
                    id={'email'}
                    required={true}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label" htmlFor={'project'}>
                  Projecto de interesse:
                </label>
                <div className="control select is-normal">
                  <select
                    className="select"
                    type={'project'}
                    name={'project'}
                    onChange={this.handleChange}
                    id={'project'}
                    required={true}
                  >
                    <option selected="true" disabled>
                      Seleccione...
                    </option>
                    <option value="1">Escolinha de Capoeira</option>
                    <option value="2">Capoeira Educa</option>
                    <option value="3">No Caminho do Quilombo</option>
                    <option value="4">Workshops</option>
                    <option value="5">Outro</option>
                  </select>
                </div>
              </div>
              <div className="field">
                <label className="label" htmlFor={'message'}>
                  Mensagem:
                </label>
                <div className="control">
                  <textarea
                    className="textarea"
                    name={'message'}
                    onChange={this.handleChange}
                    id={'message'}
                    required={true}
                  />
                </div>
              </div>
              <div className="field">
                <button className="button page-button is-link" type="submit">
                  Enviar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
