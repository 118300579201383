import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { htmlParser } from '../helpers/htmlParser';

const Readmore = ({ readmore }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="readmore-container">
        {!isExpanded && <div className='readmore-dots-container'> <p>...</p></div>}
        <div className={`readmore ${isExpanded ? 'open' : ''}`}>
          {isExpanded && <div>{htmlParser(readmore)}</div>}
        </div>
        <div>
          {!isExpanded && (
            <button
              className="button readmore-btn page-button"
              onClick={handleExpandClick}
            >
              Ler Mais
            </button>
          )}
          {isExpanded && (
            <button
              className="button readless-btn page-button"
              onClick={handleExpandClick}
            >
              Ler Menos
            </button>
          )}
        </div>
    </div>
  );
};

Readmore.propTypes = {
  readmore: PropTypes.string
};

export default Readmore;
