import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import Layout from '../components/Layout';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import VideoEmbedd from '../components/VideoEmbedd';
import Carousel from '../components/Carousel';
import ContactForm from '../pages/contacto/index';
import { htmlParser } from '../helpers/htmlParser';

// eslint-disable-next-line
export const ProjectTemplate = ({
  logoDark,
  title,
  subtitle,
  description,
  video,
  isShorts,
  images,
  partners,
  banner
}) => {
  const bannerImage = getImage(banner) || banner;

  return (
    <div>
      <section className="section section--gradient">
        <div className="container">
          <div className="project--info">
            <div className="columns">
              <div className="column is-8 is-offset-2">
                {logoDark && (
                  <div className="project-logo-container featured-thumbnail">
                    <PreviewCompatibleImage
                      className={'project-logo-img'}
                      imageInfo={{
                        image: logoDark,
                        alt: `${title + ' ' + subtitle}`,
                        width: logoDark.childImageSharp.gatsbyImageData.width,
                        height: logoDark.childImageSharp.gatsbyImageData.height
                      }}
                    />
                  </div>
                )}
                <div className="project-titles">
                  <h2>{title}</h2>
                  <h3 className="page-title project-title">{subtitle}</h3>
                </div>
                <div className="description project-desc">
                  {htmlParser(description)}
                </div>
              </div>
            </div>
          </div>
          {images && (
            <div className="project-images">
              <div className="columns">
                <div className="column is-8 is-offset-2 carousel">
                  <Carousel images={images} />
                </div>
              </div>
            </div>
          )}
          {video && video !== ' ' && (
            <div className="project-video has-text-centered">
              <div className="columns">
                <div className="column is-8 is-offset-2">
                  <VideoEmbedd src={video} isShorts={isShorts} />
                </div>
              </div>
            </div>
          )}
          {/* <div className="section has-text-centered cta-section">
            <div className="columns">
              <div className="column is-10 is-offset-1 carousel">
                <Link to={parseURL('projectos')}>
                  <button className="button cta-pages cta-button">
                    Contacto
                  </button>
                </Link>{' '}
              </div>
            </div>
          </div> */}
          <div className="section project-partners">
            <div className="columns is-centered">
              <div className="column is-narrow has-text-centered">
                <h2 className="title page-title partners-title">PARCEIROS</h2>
              </div>
            </div>
            <div className="columns is-centered has-text-centered">
              <div className="column is-10 ">
                <div className="columns">
                  {partners.map((partner, idx) => (
                    <div key={idx} className="column is-flex is-align-items-center is-justify-content-center">
                      <a
                        className="footer-author-link"
                        href={partner.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <PreviewCompatibleImage
                          className="partner-img"
                          imageInfo={{ image: partner.image, alt: partner.alt }}
                        />
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="watermark-container"
        style={{ backgroundColor: 'white', height: 250 }}
      >
        <div
          className="watermark"
          style={{ backgroundImage: `url(${bannerImage.images.fallback.src})` }}
        />
      </div>
      <section id="contacto" className="section section--gradient">
        <div className="container">
          <div className="project-contact-form">
            <ContactForm />
          </div>
        </div>
      </section>
    </div>
  );
};

ProjectTemplate.propTypes = {
  logoDark: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  subtitle: PropTypes.string,
  description: PropTypes.string,
  video: PropTypes.string,
  isShorts: PropTypes.bool,
  images: PropTypes.array,
  partners: PropTypes.array
};

const Project = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <ProjectTemplate
        logoDark={post.frontmatter.logoDark}
        title={post.frontmatter.title}
        subtitle={post.frontmatter.subtitle}
        description={post.frontmatter.description}
        video={post.frontmatter.video}
        isShorts={post.frontmatter.isShorts}
        images={post.frontmatter.images}
        partners={post.frontmatter.partners}
        banner={post.frontmatter.banner}
      />
    </Layout>
  );
};

Project.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default Project;

export const pageQuery = graphql`
  query ProjectByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        logoDark {
          childImageSharp {
            gatsbyImageData(height: 120, quality: 100, layout: CONSTRAINED)
          }
        }
        title
        subtitle
        description
        video
        isShorts
        images {
          image {
            childImageSharp {
              gatsbyImageData(width: 800, quality: 64, layout: CONSTRAINED)
            }
          }
        }
        partners {
          link
          image {
            childImageSharp {
              gatsbyImageData(width: 150, quality: 64, layout: CONSTRAINED)
            }
          }
          alt
        }
        banner {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
