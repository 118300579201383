import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import Layout from '../components/Layout';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import FullWidthImage from '../components/FullWidthImage';
import Projects from '../components/Projects';
import Team from '../components/Team';
import WorkshopsMain from '../components/WorkshopsMain';
import Readmore from '../components/Readmore';
import ContactForm from '../pages/contacto/index';

import { htmlParser } from '../helpers/htmlParser';

// eslint-disable-next-line
export const IndexPageTemplate = ({
  image,
  overlay,
  about,
  banner,
  projects,
  workshops,
  team,
  capoeira,
  partners,
  imageBottom
}) => {
  const heroImage = getImage(image) || image;
  const bannerImage = getImage(banner) || banner;
  const teamWatermark = getImage(team.banner) || team.banner;
  const extraImage = getImage(imageBottom) || imageBottom;

  return (
    <div>
      <FullWidthImage
        img={heroImage}
        className={'cover-img'}
        overlay={overlay}
      />
      <section id="sobre" className="section section--gradient">
        <div className="container">
          <div className="content">
            <div className="columns is-centered">
              <div className="column is-narrow has-text-centered">
                <h2 className="title page-title about-title">{about.title}</h2>
              </div>
            </div>
            <div className="columns">
              <div className="column is-8 is-offset-2">
                <div className="content about-content">
                  {htmlParser(about.description)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="watermark-container"
        style={{ backgroundColor: 'white', height: 250 }}
      >
        <div
          className="watermark"
          style={{ backgroundImage: `url(${bannerImage.images.fallback.src})` }}
        />
      </div>
      <section id="projectos" className="section section--gradient">
        <div className="container">
          <div className="content">
            <div className="columns is-centered">
              <div className="column is-narrow has-text-centered">
                <h2 className="title page-title projects-title">
                  {projects.title}
                </h2>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <Projects />
            </div>
          </div>
        </div>
        <div id="workshops" className="container workshops-section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <WorkshopsMain workshops={workshops} />
            </div>
          </div>
        </div>
      </section>
      <section
        id="equipa"
        className="team-section-wrapper section section--gradient"
      >
        <div className="container">
          <div className="content">
            <div className="columns is-centered">
              <div className="column is-narrow has-text-centered">
                <h2 className="title page-title team-title">{team.title}</h2>
              </div>
            </div>
          </div>
          <div className="columns is-align-items-stretch">
            <div className="column is-10 is-offset-1">
              <Team gridItems={team.members} />
            </div>
          </div>
        </div>
      </section>
      <div className="watermark-container">
        <div
          className="watermark"
          style={{
            backgroundImage: `url(${teamWatermark.images.fallback.src})`,
            opacity: 0.9
          }}
        />
      </div>
      <section id="capoeira" className="section section--gradient">
        <div className="container">
          <div className="content">
            <div className="columns is-centered">
              <div className="column is-narrow has-text-centered">
                <h2 className="title page-title capoeira-title">
                  {capoeira.title}
                </h2>
              </div>
            </div>
            <div className="columns">
              <div className="column is-8 is-offset-2">
                <div className="capoeira-content">
                  {htmlParser(capoeira.description)}
                  <div className="content btn-readmore">
                    <Readmore readmore={capoeira.readmore} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="watermark-container"
        style={{ backgroundColor: 'white', height: 250 }}
      >
        <div
          className="watermark"
          style={{ backgroundImage: `url(${bannerImage.images.fallback.src})` }}
        />
      </div>
      <section id="parceiros" className="section section--gradient">
        <div className="container">
          <div className="content">
            <div className="columns is-centered">
              <div className="column is-narrow has-text-centered">
                <h2 className="title page-title capoeira-title">PARCEIROS</h2>
              </div>
            </div>
            <div className="columns">
              <div className="column is-8 is-offset-2">
                <div className="columns">
                  {partners.map((partner, idx) => (
                    <div
                      key={idx}
                      className="column is-flex is-align-items-center is-justify-content-center"
                    >
                      <a
                        className="footer-author-link"
                        href={partner.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <PreviewCompatibleImage
                          className="partner-img"
                          imageInfo={{ image: partner.image, alt: partner.alt }}
                        />
                      </a>
                    </div>
                  ))}
                </div>
                <Link className="button page-button" to="/#contacto">
                  Junta-te
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FullWidthImage img={extraImage} className={'img-bottom'} />
      <section id="contacto" className="section section--gradient">
        <div className="container">
          <ContactForm />
        </div>
      </section>
    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  overlay: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
  about: PropTypes.object,
  banner: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  projects: PropTypes.object,
  workshops: PropTypes.object,
  team: PropTypes.shape({
    members: PropTypes.array
  }),
  capoeira: PropTypes.object,
  partners: PropTypes.array,
  imageBottom: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        overlay={frontmatter.overlay}
        about={frontmatter.about}
        banner={frontmatter.banner}
        projects={frontmatter.projects}
        workshops={frontmatter.workshops}
        team={frontmatter.team}
        capoeira={frontmatter.capoeira}
        partners={frontmatter.partners}
        imageBottom={frontmatter.imageBottom}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        overlay {
          childImageSharp {
            gatsbyImageData(width: 300, quality: 64, layout: CONSTRAINED)
          }
        }
        heading
        about {
          title
          description
        }
        banner {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        projects {
          title
        }
        workshops {
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: CONSTRAINED)
            }
          }
          title
          subtitle
        }
        team {
          title
          banner {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
          members {
            alt
            image {
              childImageSharp {
                gatsbyImageData(width: 240, quality: 64, layout: CONSTRAINED)
              }
            }
            name
            nickname
            subtitle
            description
            readmore
          }
        }
        capoeira {
          title
          description
          readmore
        }
        partners {
          link
          image {
            childImageSharp {
              gatsbyImageData(width: 150, quality: 64, layout: CONSTRAINED)
            }
          }
          alt
        }
        imageBottom {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
